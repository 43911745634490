import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userId: "",
  },
  getters: {},
  mutations: {
    // 保存token
    setTokens(state, newToken) {
      state.token = newToken;
    },
    // 保存token
    setUserId(state, newUserIdn) {
      state.userId = newUserIdn;
    },
  },
  actions: {},
  modules: {},
});
